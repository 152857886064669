import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Obfuscate } from "@south-paw/react-obfuscate-ts";

const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: auto;
  flex-flow: row;
  background-color: rgba(30, 139, 195, 1);
`;

const HeaderText = styled.div`
  border: 0px solid green;
  font-family: montserrat;
  width: 100%;
`;
const List = styled.ul`
  margin: 0;
  padding: 1rem;
  color: white;
  background-color: (30, 139, 195, 1);
`;

const BreadCrumb = styled.li`
  display: inline;
  padding: 1rem;
`;
const BreadCrumbLink = styled(Link)`
  color: white;
  text-align: center;
  padding: 24px 16px;
  color: #ccc;
  text-decoration: none;
`;
const PanelRow = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-flow: column;
    padding: 1rem;
  }
  @media (min-width: 768px) {
    flex-flow: row;
    padding: 5rem;
  }
`;

const ContactContainer = styled.div`
  border-radius: 5px;
  background-color: #f2f2f2;
  width: 100%;
  color: black;
  @media (max-width: 768px) {
    padding: 10px;
  }
  @media (min-width: 768px) {
    padding: 20px;
  }
`;

const IndexPage = () => (
  <Layout>
    <hr />
    <div class=" flex items-top justify-center min-h-screen bg-white dark:bg-gray-900 sm:items-center sm:pt-0">
      <div class="max-w-6xl mx-auto sm:px-6 lg:px-8">
        <div class="mt-0 overflow-hidden">
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="p-6 mr-2 bg-gray-100 dark:bg-gray-800 sm:rounded-lg">
              <h1 class="text-4xl sm:text-5xl text-gray-800 dark:text-white font-extrabold tracking-tight">
                We’re very approachable!
              </h1>
              <p class="text-normal text-lg sm:text-2xl font-medium text-gray-600 dark:text-gray-400 mt-2">
                Please feel free to call, send us an email or just fill in the
                simple form below. We’d love to hear from you.
              </p>

              <div class="flex items-center mt-2 text-gray-600 dark:text-gray-400">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  class="w-8 h-8 text-gray-500"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <div class="ml-4 text-md tracking-wide font-semibold w-40">
                  <Obfuscate email>contact@inflowkit.com</Obfuscate>
                </div>
              </div>
            </div>

            <div class="p-6 flex flex-col justify-center">
              <iframe
                title="Contact Form"
                src="https://plugins.crisp.chat/urn:crisp.im:contact-form:0/contact/378e5ddf-c238-49b3-94c0-58c46ad00eff"
                referrerpolicy="origin"
                sandbox="allow-forms allow-popups allow-scripts"
                width="100%"
                height="600px"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
